const EnumConfig = {
    QuestionType: {
        notDefined: 0,
        singleChoice: 1,
        multipleChoice: 2,
        subjective: 3,
        comprehensive: 4,
        numeric: 5
    }
}

export default EnumConfig;