import React from 'react';
import { LoginForm } from '../../components';
const Login = () => {

    return (
        <LoginForm/>
    )

}

export default Login;